var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('search-filter',{attrs:{"items":_vm.employees,"keyTitleForAlphabeticalSort":'employee',"additionalSortOptions":_vm.additionalSortOptions,"selectedView":'list',"excludedKeys":['employees'],"tableColumns":_vm.tableColumns,"isActionColumnEnabled":true,"allowAdd":_vm.$can('manage', 'all')},on:{"columnClick":function($event){_vm.$can('manage', 'all')?_vm.$router.push({ name: 'Employee edit', params: { employeeId: $event.id }}):false},"addItem":_vm.addEmployee},scopedSlots:_vm._u([{key:"gridView",fn:function(ref){
var item = ref.item;
return [_c('card-employee',{attrs:{"employee":item},on:{"click":function($event){_vm.$can('manage', 'all')?_vm.$router.push({ name: 'Employee edit', params: { employeeId: item.id }}):false}}})]}},{key:"listView_cell_employee",fn:function(ref){
var item = ref.item;
return [_c('b-media',{attrs:{"vertical-align":"center"},on:{"click":function($event){$event.stopPropagation();_vm.$can('manage', 'all')?_vm.$router.push({ name: 'Employee edit', params: { employeeId: item.id }}):false}},scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{attrs:{"size":"32","src":item.avatar?item.avatar.url:null,"text":_vm.avatarText(item.firstName + ' ' + item.name),"variant":"light-primary"}})]},proxy:true}],null,true)},[_c('b-link',{staticClass:"font-weight-bold d-block text-nowrap"},[_vm._v(" "+_vm._s(item._display)+" ")])],1)]}},{key:"listView_cell_email",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":'mailto:'+item.email}},[_vm._v(_vm._s(item.email))])]}},{key:"listView_cell_roles",fn:function(ref){
var item = ref.item;
return _vm._l((item.roles),function(role,index){return _c('icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(role=='ROLE_ADMIN'?_vm.$t('Admin'):_vm.$t('User')),expression:"role=='ROLE_ADMIN'?$t('Admin'):$t('User')",modifiers:{"hover":true,"top":true}}],key:index,class:'text-'+(role=='ROLE_ADMIN'?'danger':'primary'),attrs:{"icon":role=='ROLE_ADMIN'?'user-shield':'user'}})})}},{key:"listView_cell_status",fn:function(ref){
var item = ref.item;
return [_c('b-badge',{staticClass:"text-capitalize",attrs:{"pill":"","variant":'light-'+(item._isCurrentlyEmployed?'success':'secondary')}},[_vm._v(" "+_vm._s(item._isCurrentlyEmployed?_vm.$t('UnderContract'):_vm.$t('WithoutContract'))+" ")])]}},{key:"listView_cell_actions",fn:function(ref){
var item = ref.item;
return [(_vm.$can('manage', 'all'))?_c('div',{staticClass:"text-nowrap"},[_c('button-edit',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",value:(_vm.$t('Edit')),expression:"$t('Edit')",modifiers:{"hover":true,"left":true}}],attrs:{"withIcon":true,"withText":false,"withBorder":false,"size":"sm"}}),_c('button-delete',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",value:(_vm.capitalize(_vm.$t('delete'))),expression:"capitalize($t('delete'))",modifiers:{"hover":true,"left":true}}],attrs:{"disabled":item.id==1,"withIcon":true,"withText":false,"withBorder":false,"size":"sm"},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.deleteEmployeeAlert(item)}}})],1):_vm._e()]}}])}),_c('new-employee-modal',{attrs:{"newEmployee":_vm.newEmployee,"title":'NewEmployee',"isOpen":_vm.newEmployeeModalShow},on:{"update:newEmployee":function($event){_vm.newEmployee=$event},"update:new-employee":function($event){_vm.newEmployee=$event},"update:isOpen":function($event){_vm.newEmployeeModalShow=$event},"update:is-open":function($event){_vm.newEmployeeModalShow=$event},"submitValidated":function($event){return _vm.submitValidatedEmployeeLocal(_vm.newEmployee)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }